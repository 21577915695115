var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.create)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" Create role ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"display name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name","type":"text","required":""},model:{value:(_vm.role.display_name),callback:function ($$v) {_vm.$set(_vm.role, "display_name", $$v)},expression:"role.display_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Description","type":"text","required":""},model:{value:(_vm.role.description),callback:function ($$v) {_vm.$set(_vm.role, "description", $$v)},expression:"role.description"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"System Name","type":"text","required":""},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", $$v)},expression:"role.name"}})]}}],null,true)}),_c('v-treeview',{attrs:{"items":_vm.permissionsToTree(_vm.permissions.data),"children-field":"children","activatable":"","active-class":"grey lighten-4 indigo--text","selected-color":"indigo","open-on-click":"","selectable":"","dense":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"pb-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('action.create'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }