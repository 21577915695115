<template>
  <ValidationObserver v-slot="{ passes }">
    <v-form @submit.prevent="passes(create)">
      <v-card>
        <v-card-title>
          Create role
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="display name"
            rules="required"
          >
            <v-text-field
              v-model="role.display_name"
              :error-messages="errors"
              label="Name"
              type="text"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <v-text-field
              v-model="role.description"
              :error-messages="errors"
              label="Description"
              type="text"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <v-text-field
              v-model="role.name"
              :error-messages="errors"
              label="System Name"
              type="text"
              required
            />
          </ValidationProvider>
          <v-treeview
            v-model="selected"
            :items="permissionsToTree(permissions.data)"
            children-field="children"
            activatable
            active-class="grey lighten-4 indigo--text"
            selected-color="indigo"
            open-on-click
            selectable
            dense
          />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
          >
            {{ $t('action.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import permissionsToTree from './index'
import authHeader from '@/utils'

export default {
  name: 'RoleCreate',
  data: () => ({
    selected: [],
    loading: false,
    role: {
      name: null,
      display_name: null,
      description: null,
      permission_id: []
    },
    permissions: {
      data: []
    }
  }),
  mounted () {
    this.$http
      .get('/permissions/?take=50', { headers: authHeader() })
      .catch(error => this.displayError(error))
      .then((response) => {
        this.permissions = response.data
      })
  },
  methods: {
    permissionsToTree,
    create () {
      this.loading = true
      this.$http
        .post('/roles', { ...this.role, ...{ permission_id: this.selected.filter(val => val > 0) } },
          { headers: authHeader() })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.create_success') })
          this.$router.push({ name: 'dashboard.role' })
        })
    }
  }
}
</script>
