import { clone } from 'ramda'

function transformToTree (arr, idElementName, childrenElemendName) {
  const copy = clone(arr)
  const nodes = []
  let id = 0

  copy.forEach(obj => {
    id--
    const arrName = obj.name.split('.')
    obj.name = arrName.slice(1).join('.')

    // find by name in our tree, if exist, insert element inside
    const existInNode = nodes.find(v => v.name === arrName[0])
    if (existInNode) {
      existInNode[childrenElemendName].push({ id: parseInt(obj[idElementName], 10), name: obj.name })
    } else {
      nodes.push({ id: id, name: arrName[0], [childrenElemendName]: [{ id: parseInt(obj[idElementName], 10), name: obj.name }] })
    }
  })
  return nodes
}

export default function permissionsToTree (data) {
  return [{
    id: 0,
    name: 'Permissions',
    children: transformToTree(data, 'id', 'children')
  }]
}
